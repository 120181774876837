<template>
  <div>
    <!-- <div class="tw-pt-6 ">
      <v-btn

        @click="clearFaxStatus"
      >
        Clear and Retry
      </v-btn>
    </div> -->
    <v-row
      :key="refreshKey"
      class="match-height"
    >
      <v-col
        v-if="!visitData.pharmacyFax || visitData.pharmacyFax === ''"
        :key="refreshKey"
        cols="12"
        sm="8"
        offset-sm="2"
      >
        <v-card
          flat
        >
          <v-card-title>
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <!-- {{ headerSchema.statusTitle }} -->
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <div
                  class="d-flex tw-justify-center  sm:tw-justify-end"
                >
                  <v-btn
                    color="primary"
                    @click="refreshData"
                  >
                    <v-icon
                      left
                      dark
                    >
                      {{ icons.mdiRefresh }}
                    </v-icon>
                    Refresh
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-form
              ref="form"
              :key="formKey"
            >
              <p class="tw-text-lg tw-text-gray-400 tw-font-semibold">
                <!-- {{ title }} -->
                Please fill in the pharmacy details below.
              </p>
              <v-text-field
                v-model="pharmacyName"
                outlined
                :disabled="lockTextField"
                label="Pharmacy Name"
                :rules="[required]"
                background-color="fieldBg"
              ></v-text-field>
              <p class="tw-text-gray-900 tw-font-semibold">
                Please double check for accuracy. If incorrect number is entered, your pharmacy will NOT receive your Prescription.
              </p>
              <!-- <v-text-field
                v-model="faxNumber"
                outlined
                :disabled="lockTextField"
                label="Fax Number"
                placeholder="Must only consist of numbers"
                required
                background-color="fieldBg"
                :rules="[required,integerValidator]"
              ></v-text-field> -->
              <div>
                <vue-tel-input-vuetify
                  v-model="myPhone"
                  outlined
                  :only-countries="countryCode"
                  :valid-characters-only="true"
                  :error="!phone.valid && phone.number !== ''"
                  background-color="fieldBg"
                  label="Please enter a Fax Number"
                  @input="onInput"
                ></vue-tel-input-vuetify>
              </div>
              <!-- <v-card-actions> -->
              <div class="d-flex justify-center align-center">
                <div class="d-flex justify-space-between">
                  <div>
                    <v-btn
                      color="primary"
                      :disabled="buttonStatus"
                      @click="submit"
                    >
                      Submit
                    </v-btn>
                  </div>
                </div>
              </div>
              <!-- </v-card-actions> -->
            </v-form>
          </v-card-text>
        </v-card>
        <alert
          :type="alertType"
          :message="alertMessage"
          :alert-status="alertStatus"
        ></alert>
      </v-col>
      <v-col
        v-if="visitData.pharmacyFax"
        cols="12"
        sm="8"
        offset-sm="2"
      >
        <v-card :key="refresh">
          <v-card-title>
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                {{ headerSchema.statusTitle }}
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <div
                  class="d-flex tw-justify-center  sm:tw-justify-end"
                >
                  <v-btn
                    color="primary"
                    @click="refreshData"
                  >
                    <v-icon
                      left
                      dark
                    >
                      {{ icons.mdiRefresh }}
                    </v-icon>
                    Refresh
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-card-title>

          <v-card-text>
            <v-row v-if="transmissionStatus.status === 'failed'">
              <v-col>
                <v-alert
                  color="error"
                  dark
                  class="md:tw-mx-6"
                >
                  The Fax failed to transmit after 3 attempts.
                  Please push the button below to retry.
                  <div
                    class="d-flex tw-pt-6 tw-justify-center"
                  >
                    <v-btn
                      @click="clearFaxStatus"
                    >
                      Clear and Retry
                    </v-btn>
                  </div>
                </v-alert>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                Prescription date:<br />  <span class="tw-text-xl tw-text-blue-500 tw-uppercase">{{ visitStore.visitData.rxDate }}</span>
              </v-col>
              <v-col cols="12">
                Pharmacy Name: <br /> <span
                  class="tw-text-xl tw-text-blue-500 tw-uppercase"
                > {{ pharmacyName }}</span>
              </v-col>
              <!-- <v-col cols="12">
                Pharmacy Name: <br /> <span
                  v-if="transmissionStatus.status"
                  class="tw-text-xl tw-text-blue-500 tw-uppercase"
                > {{ pharmacyName }}</span>
              </v-col> -->
              <v-col
                v-if="faxNumber.length > 3"
                cols="12"
              >
                Pharmacy Fax: <br /><span
                  class="tw-text-xl tw-text-blue-500 tw-uppercase"
                > {{ formatTelNum(faxNumber) }}</span>
              </v-col>
              <v-col cols="12">
                Fax Transmission Status:<br /> <span
                  v-if="transmissionStatus.status === 'success'"
                  class="tw-text-xl tw-text-green-500 tw-uppercase"
                > {{ transmissionStatus.status }}</span>
                <span
                  v-if="transmissionStatus.status === 'transmitting' || transmissionStatus.status === 'failed'"
                  class="tw-text-xl tw-text-red-500 tw-uppercase"
                > {{ transmissionStatus.status }}</span>
              </v-col>

              <v-col cols="12">
                <span> Fax Timestamp: <br /><span
                  v-if="transmissionStatus.status === 'success'"
                  class="tw-text-blue-500 tw-uppercase"
                > {{ timestamp }}</span><span
                  v-if="transmissionStatus.status === 'transmitting' || transmissionStatus.status === 'failed'"
                  class="tw-text-red-500 tw-uppercase"
                > {{ createdAt }}</span>
                  <span
                    v-if="!transmissionStatus"
                    class="tw-text-green-500"
                  > No Previous Record of Transmit Request </span>
                </span>
              </v-col>
            </v-row>
            <v-row v-if="transmissionStatus.status !== 'failed' && transmissionStatus.status !== 'success'">
              <v-col>
                <v-alert
                  color="warning"
                  dark
                  class="tw-mx-6"
                >
                  Please note it may take a few minutes for status to update.
                </v-alert>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { required, integerValidator } from '@core/utils/validation'
import { mdiAccountOutline, mdiPrescription, mdiRefresh } from '@mdi/js'
import { visitService } from '@/api/index.js'
import { useVisitStore } from '@/stores/Visit.js'
import Alert from '@/components/UI/Alert.vue'
import { formatTelNum } from '@/util/functions/index.js'

export default {
    name: 'TransferRequest',
    components: { Alert },
    props: {
        headerSchema: {
            type: Object,
            default: () => ({}),
        },
        formKey: {
            type: Number,
            default: 0,
        },
        transferData: {
            type: Object,
            default: () => ({}),
        },
    },
    setup() {
        const visitStore = useVisitStore()

        return {
            formatTelNum,
            visitStore,
            integerValidator,
            required,
        }
    },
    data() {
        return {
            countryCode: ['CA'],
            myPhone: '',
            phone: {
                number: '',
                valid: false,
                country: undefined,
            },
            refreshKey: 0,
            alertType: 'error',
            alertMessage: 'Verification <strong>failed</strong>. Please try again.',
            dob: this.visitStore?.dob || '',
            lockTextField: false,
            refresh: 0,
            visitData: this.visitStore?.visitData,
            id: this.visitStore.visitId || localStorage.getItem('visitId') || '',
            pharmacyName: this.visitStore.visitData?.pharmacyName || '',

            // faxNumber: this.visitStore.visitData?.pharmacyFax || '',

            // timestamp: new Date(`${this.transmissionStatus.resolvedDate}z`).toLocaleString('en-CA'),
            justSubmitted: false,
            response: null,
            icons: {
                mdiAccountOutline,
                mdiPrescription,
                mdiRefresh
            },
        }
    },
    computed: {
        alertStatus() {
            if (this.validEntry) {
                return false
            }
            if (this.validEntry === null) {
                return false
            }

            return true
        },
        notValidId() {
            if (this.visitData.error === 'No data found') {
                return true
            }

            return false
        },
        faxNumber() {
            return this.visitStore.visitData.pharmacyFax
        },
        validEntry() {
            return this.visitStore.valid
        },
        form() {
            const data = {
                valid: false,
                id: this.id,

                // idInput: this.id,
                fileName: `rx-${this.id}.pdf`,
                pharmacyName: this.pharmacyName,
                faxNumber: this.phone.number,
                dob: this.dob,
            }

            return data
        },
        verifiedEntry() {
            if (this.visitData?.valid === true) {
                return true
            }

            return false
        },
        transmissionStatus() {
            if (this.visitStore.faxStatus?.status) {
                return this.visitStore.faxStatus
            }

            return false
        },
        timestamp() {
            const date = new Date(`${this.transmissionStatus.resolvedDate}Z`)

            return date.toLocaleString('en-CA')
        },
        createdAt() {
            const date = new Date(`${this.transmissionStatus.createdAt}Z`)

            return date.toLocaleString('en-CA')
        },
        buttonStatus() {
            if (this.transmissionStatus.status === 'success') {
                return true // remove this
                //   return false
            }
            if (this.justSubmitted === true) {
                return true
            }
            if (this.verifiedForm) {
                return false
            }

            return true
        },
        validFax() {
            if (this.faxNumber) {
                return integerValidator(this.faxNumber)
            }

            return false
        },
        validName() {
            if (this.pharmacyName) {
                return required(this.pharmacyName)
            }

            return false
        },
        verifiedForm() {
            if (this.validName && this.phone.valid) {
                return true
            }

            return false
        },

        // title() {
        //     //   return this.id ? 'Edit' : 'Create'
        //     return this.id ? 'Please enter your details below' : 'Create'
        // },
    },
    watch: {
    // form(val) {
    //   console.log(val)
    //   console.log(this.form)
    // },
    // name(val) {
    //   console.log(val)
    // },
    // id(val) {
    //   console.log(val)
    // },
        transmissionStatus() {
            //   console.log(val)
            this.form.pharmacyName = this.visitStore.visitData.pharmacyName ? this.visitStore.visitData.pharmacyName : ''
            this.form.faxNumber = this.visitStore.visitData.pharmacyFax ? this.visitStore.visitData.pharmacyFax : ''
        },
    },
    mounted() {
        setTimeout(() => {
            this.getVisitData()
            this.visitData = this.visitStore?.visitData
            this.pharmacyName = this.visitStore.visitData.pharmacyName ? this.visitStore.visitData.pharmacyName : ''

            // this.faxNumber = this.visitStore.visitData.pharmacyFax ? this.visitStore.visitData.pharmacyFax : ''
        }, 500)

    // this.status()
    },
    methods: {
        onInput(formattedNumber, { number, valid, country }) {
            this.phone.number = number.significant
            this.phone.valid = valid
            this.phone.country = country && country.name
        },
        async refreshData() {
            this.$emit('refreshData')
            this.visitStore.setVisit({ id: this.visitStore.visitId, dob: this.visitStore.dob }).then(() => {
                this.getVisitData()
                this.pharmacyName = this.visitData.pharmacyName ? this.visitData.pharmacyName : ''
                this.faxNumber = this.visitData.pharmacyFax ? this.visitData.pharmacyFax : ''
                this.refreshKey += 1
            })
        },
        async submitId() {
            this.id = this.idInput
            await this.getVisitData()
        },
        updateDob(dob) {
            this.dob = dob
            this.getVisitData()
        },
        async getVisitData() {
            this.visitData = this.visitStore.visitData
        },

        // async refreshData() {
        //     console.log(this.visitStore.getForm)
        //     this.visitData = await visitService.getVisit(this.visitStore.getForm)
        // },
        async submit() {
            const transferData = {
                pharmacyName: this.pharmacyName,
                pharmacyFax: this.phone.number,
                visitId: this.visitStore.visitId,
                dateofBirth: this.visitStore.dob,
                patientId: this.visitStore.patientId
            }

            // this.visitStore.setFaxStatus({})
            await visitService.updateVisitTransferPharmacy(transferData)
            this.response = await visitService.sendFax(this.form)
            this.justSubmitted = true
            this.refresh += 1
            setTimeout(() => {
                this.refreshData()
            }, 5000)

            //   this.justSubmitted = false
        },
        async clearFaxStatus() {
            const transferData = {
                pharmacyName: '',
                pharmacyFax: '',
                visitId: this.visitStore.visitId,
                dateofBirth: this.visitStore.dob,
                patientId: this.visitStore.patientId
            }
            await visitService.resetVisitTransferPharmacy(transferData)

            // this.response = await visitService.sendFax(this.form)
            // this.justSubmitted = true
            this.refreshData()
            this.refresh += 1
            setTimeout(() => {
                this.refreshData()
            }, 500)

            this.justSubmitted = false
        },
        async status() {
            this.transmissionStatus = await visitService.getFaxStatus(this.form)
        },
    },
}
</script>

<style lang="scss" scoped>
.row.match-height {
  .v-card {
    height: 100%;
  }
}
</style>
