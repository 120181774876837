<template>
  <div>
    <form-header
      :header-schema="headerSchema"
      @refreshData="refreshData"
    ></form-header>
    <form-template
      v-if=" visitStore.visitData.status[1].value === 'pending' || visitStore.visitData.status[1].value === 'transfer'"
      :header-schema="headerSchema"
      :form-key="formKey"
      @refreshData="refreshData"
    ></form-template>
    <form-dialog-btn v-show="false"></form-dialog-btn>
    <v-col
      v-if="visitStore.visitData.status[1].value === 'inprogress' ||visitStore.visitData.status[1].value === 'complete' || visitStore.visitData.status[1].value === 'action'"
      sm="6"
      cols="12"
      offset-sm="3"
    >
      <v-card>
        <div class="d-flex flex-column-reverse flex-md-row">
          <div>
            <v-card-title>
              Prescription is being processed by PharmaSmart
            </v-card-title>
            <v-card-text>
              Prescription is being processed by PharmaSmart and can not be sent to another pharmacy. Please contact us if you would like to cancel the prescription.
            </v-card-text>
            <v-card-actions class="dense">
              <v-btn
                text
                color="primary"
                dark
                @click="popupzendesk"
              >
                Contact us
              </v-btn>
            </v-card-actions>
          </div>
          <div class="pa-4">
            <v-img
              src="@/assets/images/banners/onlinePharmacy.png"
              :class="$vuetify.breakpoint.mdAndUp ? 'rounded-lg':'rounded-0'"
              height="100%"
              :max-width="$vuetify.breakpoint.mdAndUp ? '220':'100%'"
            ></v-img>
          </div>
        </div>
      </v-card>
    </v-col>
  </div>
</template>

<script>
import FormTemplate from '@/components/Forms/FormTemplate.vue'
import FormHeader from '@/components/UI/FormHeader.vue'
import { useVisitStore } from '@/stores/Visit.js'
import formDialogBtn from '@/components/Forms/formDialogBtn.vue'

export default {
    name: 'TransferPage',
    components: {
        FormTemplate,
        FormHeader,
        formDialogBtn,
    },
    setup() {
        const visitStore = useVisitStore()

        return {
            visitStore,
        }
    },
    data() {
        return {
            refreshBool: true,
            formKey: 0,
            headerSchema: {
                title: 'Transmit Prescription to your Pharmacy',
                subtitle: 'Welcome, please enter your details below to begin your transfer.',
                icon: 'mdi-transfer-right',
                pageName: 'Transmit',
                to: 'transfer',
                buttonLabel: 'Refresh',
                statusTitle: 'Report',
            },
        }
    },
    computed: {
        activateSignup() {
            if (this.visitStore.patientData?.detailedAddress) {
                return false
            }

            // this.$root.$emit('updateMainBioDialog', true)

            return true
        },
    },
    mounted() {
        setTimeout(() => {
            this.refreshData()
            if (this.activateSignup) {
                // wait 2 seconds
                setTimeout(() => {
                    this.$root.$emit('updateMainBioDialog', true)
                }, 1000)

            // this.$root.$emit('updateMainBioDialog', true)
            }
        }, 1000)
    },
    methods: {
        async refreshData() {
            // await this.visitStore.setVisit({ id: this.visitStore.visitId, dob: this.visitStore.dob })

            // 50 ms timeout
            // setTimeout(() => {
            //     this.refreshBool = false
            // }, 50)
            // this.refreshBool = true
            this.formKey += 1
        },
        popupzendesk() {
            // console.log(this.$zendesk.zE)
            this.$zendesk.zE('webWidget', 'open')
        },
    },
}
</script>
