<template>
  <div class="tw-bg-gray-100 dark:tw-bg-gray-800 tw-py-6 lg:tw-py-8">
    <div class="tw-container tw-px-6 tw-mx-auto tw-flex tw-flex-col md:tw-flex-row tw-items-start md:tw-items-center tw-justify-between">
      <div>
        <p class="tw-flex tw-items-center tw-text-indigo-700 tw-text-xs">
          <router-link :to="{ name: 'home'}">
            <span>Home</span>
          </router-link>
          <!-- <span class="tw-mx-2">&gt;</span>
          <router-link :to="{ name: 'apps-user-view'}">
            <span>Profile</span>
          </router-link> -->
          <span class="tw-mx-2">&gt;</span>
          <router-link :to="{ name: headerSchema.to}">
            <span>{{ headerSchema.pageName }}</span>
          </router-link>
        </p>
        <h4 class="tw-text-2xl tw-font-bold tw-leading-tight tw-text-gray-800 dark:tw-text-gray-100">
          <div v-html="headerSchema.title"></div>
        </h4>
      </div>
      <div class="tw-mt-6 md:tw-mt-0">
        <!-- <button class="tw-mr-3 tw-bg-gray-200 dark:tw-bg-gray-700 focus:tw-outline-none tw-transition tw-duration-150 tw-ease-in-out tw-rounded hover:tw-bg-gray-300 tw-text-indigo-700 dark:hover:tw-bg-gray-600 dark:tw-text-indigo-600 tw-px-5 tw-py-2 tw-text-sm">
          Back
        </button> -->
        <!-- <v-btn
          dense
          medium
          color="primary"
          :to="{ name: 'apps-user-view'}"
        >
          <v-icon
            left
            dark
          >
            {{ icons.mdiPrescription }}
          </v-icon>
          list
        </v-btn> -->
        <!-- <v-btn
          color="primary"
          @click="refreshData"
        >
          {{ headerSchema.buttonLabel }}
        </v-btn> -->
        <!-- <button
          class="tw-transition focus:tw-outline-none tw-duration-150 tw-ease-in-out hover:tw-bg-indigo-600 tw-bg-indigo-700 tw-rounded tw-text-white tw-px-8 tw-py-2 tw-text-sm"
          @click="refreshData"
        >
          {{ headerSchema.buttonLabel }}
        </button> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mdiPrescription } from '@mdi/js'

export default {
    name: 'GreyWithBreadcrumbs',
    props: {
    // items: {
    //   type: Array,
    //   default: () => [],
    // },
        headerSchema: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            icons: { mdiPrescription },
        }
    },
    methods: {
        refreshData() {
            this.$emit('refreshData')
        },
    },
}
</script>
